import React from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import AppHeader from "./AppHeader"; // Make sure you have an AppHeader component
import { Outlet } from "react-router-dom";

const { Content } = Layout;

const MainLayout = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <Sidebar />
    <Layout className="site-layout">
      <AppHeader />
      <Content style={{ margin: "0px 0px 0", overflow: "initial" }}>
        <div style={{ padding: "20px" }}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  </Layout>
);

export default MainLayout;
