import React from "react";
import { Layout, Avatar, Typography, Badge, Divider, Button } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../context/AuthContext";

const { Header } = Layout;
const { Text } = Typography;

function AppHeader() {
  const { logout, user } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Header
      style={{
        background: "#fff",
        padding: "0 16px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "64px",
        zIndex: 1,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Divider type="vertical" style={{ height: "32px", marginRight: "10px" }} />
        <Badge dot status="success" offset={[-5, 45]} style={{ width: "12px", height: "12px" }}>
          <Avatar src={user.userProfilePicture} icon={!user.userProfilePicture && <UserOutlined />} size="large" />
        </Badge>
        <div
          style={{
            marginLeft: "10px",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Text strong>{user.userName}</Text>
          <Text type="secondary" style={{ fontSize: "12px" }}>
            {user.userPosition}
          </Text>
        </div>
        <Button
          type="primary"
          icon={<LogoutOutlined />}
          style={{
            marginLeft: "20px",
            alignSelf: "center",
            color: "gray",
            backgroundColor: "white",
            border: "1px solid gray",
            marginRight: "3px",
          }}
          onClick={handleLogout}
        ></Button>
      </div>
    </Header>
  );
}

export default AppHeader;
