import React from "react";
import bordeaulogo from "../assets/BillImages/bordeaulogo.png";

// Since we're not using the shadcn/ui components, we'll create simple styled components
const Card = ({ children, className }) => (
  <div className={`bg-white shadow-md rounded-lg ${className}`}>{children}</div>
);
const CardHeader = ({ children, className }) => (
  <div className={`p-4 border-b ${className}`}>{children}</div>
);
const CardContent = ({ children, className }) => (
  <div className={`p-4 ${className}`}>{children}</div>
);
const CardFooter = ({ children, className }) => (
  <div className={`p-4 border-t ${className}`}>{children}</div>
);

export default function POSReceipt() {
  const receiptData = {
    storeName: "P&S",
    storeLocation: "Hikkaduwa",
    address: "No.292A, Galle Road, Hikkaduwa",
    hours: "Open 6AM - Close 9PM",
    phone: "Tel No: 091-7223546",
    orderType: "Order: Dine In",
    billNo: "Bill No: 189-136120",
    cashier: "Cashier: 2000.PNS",
    date: "Bill Date: 04/09/2024 16:09",
    items: [
      { name: "Fish Chinese Roll", qty: 1.0, price: 160.0, discount: 0 },
      {
        name: "Chicken & Mushroom Chinese Roll",
        qty: 1.0,
        price: 240.0,
        discount: 0,
      },
      { name: "Chicken Creamy Puff", qty: 1.0, price: 170.0, discount: 0 },
      {
        name: "Chicken Bacon & Egg Pastry",
        qty: 1.0,
        price: 230.0,
        discount: 0,
      },
      { name: "Milo Pkt", qty: 1.0, price: 130.0, discount: 0 },
      { name: "Iced Coffee", qty: 1.0, price: 210.0, discount: 0 },
    ],
    netTotal: 1140.0,
    cash: 1200.0,
    cashBalance: 60.0,
    totalQuantity: 6.0,
  };

  return (
    <Card className="w-80 mx-auto font-mono text-xs leading-tight">
      <CardHeader className="text-center pb-2">
        <div className="text-xl font-bold border-2 border-black rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-1">
          P&S
        </div>
        <h2 className="font-bold">{receiptData.storeLocation}</h2>
        <p>{receiptData.address}</p>
        <p>{receiptData.hours}</p>
        <p>{receiptData.phone}</p>
      </CardHeader>
      <CardContent className="space-y-2">
        <div>
          <p>{receiptData.orderType}</p>
          <p>{receiptData.billNo}</p>
          <p>{receiptData.cashier}</p>
          <p>{receiptData.date}</p>
        </div>
        <div>
          <p>Item Description</p>
          <p className="flex justify-between">
            <span>Qty</span>
            <span>U/Price</span>
            <span>%</span>
            <span>Discount</span>
            <span>Amount</span>
          </p>
          {receiptData.items.map((item, index) => (
            <div key={index} className="mt-1">
              <p>{item.name}</p>
              <p className="flex justify-between">
                <span>{item.qty.toFixed(3)}</span>
                <span>{item.price.toFixed(2)}</span>
                <span>0%</span>
                <span>0.00</span>
                <span>{(item.qty * item.price).toFixed(2)}</span>
              </p>
            </div>
          ))}
        </div>
        <div className="border-t border-black pt-2">
          <p className="flex justify-between">
            <span>Net Total:</span>
            <span>{receiptData.netTotal.toFixed(2)}</span>
          </p>
          <p className="flex justify-between">
            <span>Cash :</span>
            <span>{receiptData.cash.toFixed(2)}</span>
          </p>
          <p className="flex justify-between">
            <span>Cash Balance :</span>
            <span>{receiptData.cashBalance.toFixed(2)}</span>
          </p>
          <p>Total Quantity : {receiptData.totalQuantity.toFixed(3)}</p>
        </div>
      </CardContent>
      <CardFooter className="text-center pt-0">
        <p className="w-full">Thank You</p>
      </CardFooter>
    </Card>
  );
}
