import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Result } from "antd";
import axios from "axios";
import DashSec1 from "./DashbordSections/DashSec1";
import DashSec2 from "./DashbordSections/DashSec2";
import DashSec3 from "./DashbordSections/DashSec3";
import DashSec4 from "./DashbordSections/DashSec4";
import { useNavigate } from "react-router-dom";
import Timer from "../assets/SideBarImages/Timer.png";
import { ClockCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Dashboard = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [Forbidden, setForbidden] = useState(false);

  const handleButtonClick = () => {
    navigate("/order-management");
  };

  const handleOnButtonClick = () => {
    navigate("/ongoing-orders");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://bkpos.debuxglobal.com/api/yearreport", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const data = await response.data;

        console.log("API Response:", data); // Debugging line
        if (data.success === false) {
          setForbidden(true);
        } else {
          setForbidden(false); // Ensure the state is reset if the user is authorized
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setForbidden(true); // Handle errors by showing the forbidden page
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {Forbidden ? (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          icon={<ClockCircleOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />}
        />
      ) : (
        <div style={{ backgroundColor: "#F4F4F4", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h1 style={{ fontSize: "36px", fontWeight: "bold", color: "#000000", fontFamily: "Nunito" }}>Dashboard</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "700",
                  width: "180px",
                  height: "50px",
                  marginRight: "10px",
                  border: isHovered ? "1px solid #F2890D" : "1px solid transparent",
                  color: isHovered ? "#F2890D" : "inherit",
                  transition: "border-color 0.3s ease",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleButtonClick}
              >
                <ClockCircleOutlined style={{ marginRight: "8px" }} />
                New Order
              </Button>
              <Button
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "700",
                  width: "180px",
                  height: "50px",
                }}
                onClick={handleOnButtonClick}
              >
                <ClockCircleOutlined style={{ marginRight: "8px" }} />
                On Going Orders
              </Button>
            </div>
          </div>
          <div>
            <Row gutter={[16, 16]} justify="start">
              <Col span={10}>
                <DashSec1 />
              </Col>
              <Col span={14}>
                <DashSec2 />
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Row gutter={[16, 16]} justify="start">
              <Col span={16}>
                <DashSec3 />
              </Col>
              <Col span={8}>
                <DashSec4 />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
