// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWreoEj3B-MGw2mmKJ5Gj3NxjwZuYztcI",
  authDomain: "debuxpos.firebaseapp.com",
  projectId: "debuxpos",
  storageBucket: "debuxpos.appspot.com",
  messagingSenderId: "228205096653",
  appId: "1:228205096653:web:d3ee77aa77177807d9c384",
  measurementId: "G-21VQT0S6Q9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
