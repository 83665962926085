import React, { useEffect, useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { Card, Typography, Select } from "antd";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import moment from "moment";

const { Text } = Typography;
const { Option } = Select;

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const DashSec3 = () => {
  const chartRef = useRef(null); // Use a ref to access the chart instance
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month

  const fetchBills = async (month) => {
    try {
      const response = await fetch(`https://bkpos.debuxglobal.com/api/monthreport/${month}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await response.json();

      if (data.length > 0 && chartRef.current) {
        const groupedBills = groupBillsByWeek(data);
        const currentMonthData = groupedBills.currentMonth;
        const previousMonthData = groupedBills.previousMonth;

        const labels = currentMonthData.map((_, index) => `Week ${index + 1}`);

        const ctx = chartRef.current.ctx;

        const gradientBar1 = ctx.createLinearGradient(0, 0, 0, chartRef.current.height);
        gradientBar1.addColorStop(0, "#1BDBF2");
        gradientBar1.addColorStop(1, "#FFFFFF");

        const gradientBar2 = ctx.createLinearGradient(0, 0, 0, chartRef.current.height);
        gradientBar2.addColorStop(0, "#F39019");
        gradientBar2.addColorStop(1, "#FFFFFF");

        const datasets = [
          {
            label: "This Month",
            data: currentMonthData,
            backgroundColor: gradientBar1,
            borderRadius: { topLeft: 20, topRight: 20 },
          },
          {
            label: "Last Month",
            data: previousMonthData,
            backgroundColor: gradientBar2,
            borderRadius: { topLeft: 20, topRight: 20 },
          },
        ];

        setChartData({
          labels,
          datasets,
        });
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchBills(selectedMonth);
  }, [selectedMonth]);

  const chartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Nunito", // Apply 'Nunito' font to x-axis labels
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Nunito", // Apply 'Nunito' font to y-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "Nunito", // Apply 'Nunito' font to legend labels
          },
        },
      },
      tooltip: {
        enabled: true,
        bodyFont: {
          family: "Nunito", // Apply 'Nunito' font to tooltip body text
        },
        titleFont: {
          family: "Nunito", // Apply 'Nunito' font to tooltip title text
        },
      },
    },
  };

  const groupBillsByWeek = (bills) => {
    const currentMonth = selectedMonth - 1; // JavaScript months are 0-based
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;

    const currentMonthBills = bills.filter((bill) => new Date(bill.created_at).getMonth() === currentMonth);
    const previousMonthBills = bills.filter((bill) => new Date(bill.created_at).getMonth() === previousMonth);

    const groupByWeek = (bills) => {
      const weeks = [0, 0, 0, 0]; // Start with 4 weeks
      bills.forEach((bill) => {
        const date = new Date(bill.created_at);
        const weekNumber = Math.floor((date.getDate() - 1) / 7); // Simplified week calculation
        weeks[weekNumber] += bill.net_total;
      });
      // Only add a fifth week if there's data
      if (bills.some((bill) => new Date(bill.created_at).getDate() > 28)) {
        weeks.push(0); // Add a fifth week
        bills.forEach((bill) => {
          const date = new Date(bill.created_at);
          const weekNumber = Math.floor((date.getDate() - 1) / 7);
          if (weekNumber === 4) {
            weeks[4] += bill.net_total;
          }
        });
      }
      return weeks.filter((week) => week > 0); // Filter out any empty weeks
    };

    return {
      currentMonth: groupByWeek(currentMonthBills),
      previousMonth: groupByWeek(previousMonthBills),
    };
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  return (
    <Card
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={{ fontFamily: "Nunito", fontSize: "24px", fontWeight: "700" }}>Weekly Sales Comparison</Text>
        <Select value={selectedMonth} onChange={handleMonthChange} style={{ fontFamily: "Nunito", width: 120 }}>
          {moment.months().map((month, index) => (
            <Option key={index + 1} value={index + 1}>
              {month}
            </Option>
          ))}
        </Select>
      </div>
      <Bar ref={chartRef} data={chartData} options={chartOptions} />
    </Card>
  );
};

export default DashSec3;
