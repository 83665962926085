import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Row, Col, Typography, Card } from "antd";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from "chart.js";

const { Text } = Typography;

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const DashSec1 = () => {
  const [lastUpdated, setLastUpdated] = useState("");
  const [totalNetAmount, setTotalNetAmount] = useState(0);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://bkpos.debuxglobal.com/api/yearreport", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const data = await response.json();

        // Process the returned data
        const dates = data.map((group) => `${group.start_date} - ${group.end_date}`);
        const netTotals = data.map((group) => group.total);

        setLastUpdated(new Date().toLocaleTimeString());

        const totalAmount = netTotals.reduce((sum, current) => sum + current, 0);
        setTotalNetAmount(
          totalAmount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );

        setChartData({
          labels: dates,
          datasets: [
            {
              label: "Net Total",
              data: netTotals,
              borderColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  return null;
                }

                const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
                gradient.addColorStop(0, "#FF5BEF");
                gradient.addColorStop(1, "#5BC4FF");

                return gradient;
              },
              backgroundColor: "rgba(63, 81, 181, 0.1)",
              fill: true,
              tension: 0.1,
              pointHoverRadius: 6,
              pointRadius: 4,
            },
          ],
        });
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  const chartOptions = {
    maintainAspectRatio: false, // Allow the chart to stretch and fit its container
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (tooltipItems) {
            // Remove time part and only show the date range
            return tooltipItems[0].label;
          },
          label: function (tooltipItem) {
            return `Net Total: ${tooltipItem.formattedValue} LKR`;
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        padding: "20px",
        height: "380px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col span={12} style={{ textAlign: "left" }}>
          <Text
            style={{
              fontFamily: "Nunito",
              fontSize: "18px",
              fontWeight: "700",
              display: "block",
            }}
          >
            Total Balance
          </Text>
          <Text
            style={{
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "700",
              color: "#909090",
              marginTop: "-20px",
            }}
          >
            Last Updated At: {lastUpdated}
          </Text>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Text
            style={{
              fontFamily: "Nunito",
              fontSize: "28px",
              fontWeight: "700",
            }}
          >
            {totalNetAmount} LKR
          </Text>
        </Col>
      </Row>
      <Card
        style={{
          border: "none",
          padding: 0,
          margin: 0,
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <div style={{ height: "100%" }}>
          <Line data={chartData} options={chartOptions} height={200} />
        </div>
      </Card>
    </div>
  );
};

export default DashSec1;
