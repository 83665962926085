import React from "react";
import bordeaulogo from "../assets/BillImages/bordeaulogo.png"; // Make sure this path is correct

// Reusable styled components
const Card = ({ children, className }) => (
  <div className={`bg-white shadow-md rounded-lg ${className}`}>{children}</div>
);
const CardHeader = ({ children, className }) => <div className={`p-4 border-b ${className}`}>{children}</div>;
const CardContent = ({ children, className }) => <div className={`p-4 ${className}`}>{children}</div>;
const CardFooter = ({ children, className }) => <div className={`p-4 border-t ${className}`}>{children}</div>;

const Kot = ({ items, table, order, date, isPrint }) => {
  // Method to calculate the total quantity
  const calculateTotalQuantity = () => {
    return items.reduce((total, item) => total + item.quantity, 0);
  };

  const totalQuantity = calculateTotalQuantity(); // Calculate the total quantity

  return (
    <Card className="w-80 mx-auto font-mono">
      <Card className="w-80 mx-auto font-mono">
        <CardHeader className="text-center pb-2 border-b">
          <img src={bordeaulogo} alt="Bord Eau Logo" className="w-20 h-6 mx-auto" />
          <p className="text-sm">212 Galle Rd, Balapitiya 80550</p>
          <p className="text-sm">077 756 6503</p>
        </CardHeader>
      </Card>
      <CardContent className="space-y-2 pt-4">
        <p className="text-sm">{date}</p>
        <div className={`border-b pb-1 flex justify-between text-sm font-bold`}>
          <span>Qty</span>
          <span>Item</span>
        </div>
        {items.map((item, index) => (
          <div key={index} className="text-sm">
            <div className="flex justify-between">
              <span className="font-bold">{item.quantity}</span>
              <span className="flex-1 ml-4 text-right">{item.name}</span>
            </div>
            {/* Order Note placed below item details */}
            {item.note && <div className="text-xs italic text-gray-500 mt-1">Note: {item.note}</div>}
          </div>
        ))}
      </CardContent>

      <CardFooter className="text-center pt-4 border-t">
        <p className="text-sm font-bold">Total Quantity: {totalQuantity}</p>
        <p className="text-sm mt-2">Order Received!</p>
        <p className="text-sm">Start Prep and Check Status.</p>
      </CardFooter>
    </Card>
  );
};

export default Kot;
